import React, { useEffect, useState } from 'react';
import Header from './Header';

const AdminLoginPage = () => {
  const [error, setError] = useState('');

  useEffect(() => {
    const handleInput = (e) => {
      let key = e.key;
      let target = e.target;
      let myLength = 0;
      if (target.value !== undefined) {
        myLength = target.value.length;
      }
      if (myLength >= 1 && key !== 'Backspace') {
        let next = target.nextElementSibling;
        if (target.id < 3) {
          next.focus();
        }
      } else if (myLength === 0 && key === 'Backspace') {
        let previous = target.previousElementSibling;
        if (target.id > 1) {
          previous.focus();
        }
      }

      if (key === 'Enter') {
        let input =
          document.getElementById('1').value +
          document.getElementById('2').value +
          document.getElementById('3').value;
        if (input.length < 3) {
          setError('Please provide a valid code');
        } else {
          setError('');
          console.log(input);
        }
      }
    };
    document.addEventListener('keydown', handleInput);

    return () => window.removeEventListener('keydown', handleInput);
  });

  return (
    <div className="admin-login">
      <Header />
      <div className="admin-login__title"> ENTER ADMIN CODE </div>
      {error && <div className="admin-login__error">{error}</div>}
      <form id="container" className="admin-login__box" autoComplete="off">
        <input
          id="1"
          className="admin-login__input"
          type="text"
          maxLength={1}
        />
        <input
          id="2"
          className="admin-login__input"
          type="text"
          maxLength={1}
        />
        <input
          id="3"
          className="admin-login__input"
          type="text"
          maxLength={1}
        />
      </form>
      <div className="admin-login__text">Hit 'ENTER' to submit</div>
    </div>
  );
};

export default AdminLoginPage;
