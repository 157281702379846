import React from 'react';
import teamData from '../../constants/team';

const Founders = () => {
  return (
    <div className="founders">
      <div className="founders__title"><span>OUR FOUNDERS</span></div>
      <div className="founders__container">
        {
          teamData.map(({ id, name, desc, image }) => (
            <div key={id} className="founder-card">
              <img className="founder-card__image" alt={name} src={image} />
              <div className="founder-card__name">{name}</div>
              <div className="founder-card__text">{desc}</div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default Founders;