export default [
  {
    id: 1,
    name: "OUR MISSION",
    desc: "Here at Best Path Transit, we seek to maximize the efficiency of school transportation systems by bringing all stakeholders onto one unified platform that fosters secure and open communication between all stakeholders."
  },
  {
    id: 2,
    name: "OUR VALUES",
    desc: "From our products to our customer service, we believe that the best outcomes are achieved through open communication. Through maintaining superior, secure communication, we allow everyone to focus on what's most important."
  },
]