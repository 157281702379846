import React from 'react';
import Header from './Header';
import Hero from './home-page/Hero';
import Ecosystem from './home-page/Ecosystem';
import Features from './home-page/Features';

const HomePage = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Ecosystem />
      <Features />
    </div>
  );
};

export default HomePage;