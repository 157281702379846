import abhiImg from '../assets/abhi-bio.jpg'
import sohumImg from '../assets/sohum-bio.jpg'

export default [
  {
    id: 1,
    name: "SOHUM SHAH",
    desc: "Sohum Shah is a co-founder of Best Path Transit. In addition to co-founding Best Path Transit, Sohum studies Applied Mathematics and Financial Engineering at the University of California, Berkeley.",
    image: sohumImg
  },
  {
    id: 2,
    name: "ABHI MOHNANI",
    desc: "Abhi Mohnani is a co-founder of Best Path Transit. In addition to co-founding Best Path Transit, Abhi studies Computer Science Engineering and Economics at Johns Hopkins University.",
    image: abhiImg
  }
]