import React from 'react';
import Header from './Header';
import AboutUs from './about-page/AboutUs';
import Missions from './about-page/Missions';
import Founders from './about-page/Founders';

const AboutPage = () => {
  return (
    <div>
      <Header />
      <AboutUs />
      <Missions/>
      <Founders/>
    </div>
  )
};

export default AboutPage; 