import busTracking from '../assets/bus-tracking.jpg';
import studentApp from '../assets/student-app.jpg';
import notes from '../assets/notes.jpg';

export default [
  {
    id: 1,
    title: 'BUS TRACKING',
    image: busTracking,
    desc: 'Our suite of products creates transparency through allowing for live bus and fleet tracking for students and admins.'
  },
  {
    id: 2,
    title: 'STUDENT APP',
    image: studentApp,
    desc: 'Our mobile application provides students and parents with a secure and efficient way to correspond with their schools.'
  },
  {
    id: 3,
    title: 'ROUTING',
    image: notes,
    desc: 'Our unique student-demand-based routing approach ensures that your fleet will always be operating at full efficiency.'
  },
]