import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../assets/bpt-logo.png';

const Header = () => {
  useEffect(() => {
    const handleScroll = (e) => {
      var header = document.getElementById('header');
      if (
        document.documentElement.scrollTop ||
        document.body.scrollTop > window.innerHeight
      ) {
        header.classList.add('header__colored');
      } else {
        header.classList.remove('header__colored');
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0 });
  };

  if (
    window.location.pathname === '/' ||
    window.location.pathname === '/contact'
  ) {
    return (
      <div id="header" className="header">
        <div className="header__left">
          <Link to="/" onClick={scrollTop}>
            <img className="header__image" alt="logo" src={logo} />
          </Link>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/"
            exact
            onClick={scrollTop}
          >
            <span data-content="HOME">HOME</span>
          </NavLink>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/about"
          >
            <span data-content="ABOUT US">ABOUT US</span>
          </NavLink>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/contact"
          >
            <span data-content="CONTACT">CONTACT</span>
          </NavLink>
        </div>
        <Link className="header__login-link" to="/login">
          ADMIN LOGIN
        </Link>
      </div>
    );
  } else if (window.location.pathname === '/about') {
    return (
      <div id="header" className="header white">
        <div className="header__left">
          <Link to="/" onClick={scrollTop}>
            <img className="header__image" alt="logo" src={logo} />
          </Link>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/"
            exact
            onClick={scrollTop}
          >
            <span data-content="HOME">HOME</span>
          </NavLink>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/about"
          >
            <span data-content="ABOUT US">ABOUT US</span>
          </NavLink>
          <NavLink
            className="header__link"
            activeClassName="header__link--active"
            to="/contact"
          >
            <span data-content="CONTACT">CONTACT</span>
          </NavLink>
        </div>
        <Link className="header__login-link--blue" to="/login">
          ADMIN LOGIN
        </Link>
      </div>
    );
  } else if (window.location.pathname === '/login') {
    return (
      <div id="header" className="header">
        <Link to="/">
          <img className="header__image" alt="logo" src={logo} />
        </Link>
        <NavLink
          className="header__link"
          activeClassName="header__link--active"
          to="/"
        >
          <span data-content="BACK TO HOME">BACK TO HOME</span>
        </NavLink>
      </div>
    );
  } else {
    return <div id="header">NO HEADER</div>;
  }
};

export default Header;
