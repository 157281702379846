import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import { FaChevronDown } from 'react-icons/fa';
import heroImg from '../../assets/bus.jpg';
import compressedHeroImg from '../../assets/compressed-bus.jpg';

const Hero = () => {
  const smoothScroll = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (
    <div id="hero" className="hero">
      <div className="hero__info">
        <div className="hero__underline" />
        <div className="hero__text">
            We seek to build partnerships with school transportation departments to help them run more efficiently and cost effectively.
        </div>
        <button
          className="hero__button"
          onClick={() => {
            smoothScroll('#ecosystem');
          }}
        >
          LEARN MORE
        </button>
      </div>
      <div className="hero__triangle" />
      <div className="hero__image-container">
        <ProgressiveImage src={heroImg} placeholder={compressedHeroImg}>
          {(src) => <img className="hero__image" alt="bus" src={src} />}
        </ProgressiveImage>
      </div>
      <button
        className="arrow-down"
        onClick={() => {
          smoothScroll('#ecosystem');
        }}
      >
        <FaChevronDown />
      </button>
    </div>
  );
};

export default Hero;
