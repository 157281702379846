import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import aboutImg from '../../assets/about-us.jpg';
import compressedAboutImg from '../../assets/compressed-about-us.jpg';
import studentsImg from '../../assets/students.jpg';
import compressedStudentsImg from '../../assets/compressed-students.jpg';

const AboutUs = () => {
  return (
    <div className="about-us">
      <ProgressiveImage src={aboutImg} placeholder={compressedAboutImg}>
        {(src) => (
          <img className="about-us__bg-image" alt="about us" src={src} />
        )}
      </ProgressiveImage>
      <div className="about-us__color-layer" />
      <div className="about-us__info">
        <div className="about-us__title">ABOUT US</div>
        <div className="about-us__container">
          <div className="about-us__story">
            <div className="about-us__story__title">OUR STORY</div>
            <div className="about-us__story__text">
              As part of a high school research project, Sohum identified that
              daily changes in school bus ridership end up compounding into
              massive inefficiencies at a system level. As a result, he sought
              to create a system driven by a powerful dynamic-routing algorithm
              that isolated and removed these efficiencies while causing minimal
              disruption to students, parents, drivers, and administrators. Upon
              realizing the effectiveness of this system in his home town, Sohum
              partnered with Abhi to build out the Best Path Transit ecosystem
              to help school districts throughout the United States shuttle
              their students more efficiently and cost effectively.
            </div>
          </div>
          <ProgressiveImage
            src={studentsImg}
            placeholder={compressedStudentsImg}
          >
            {(src) => (
              <img className="about-us__image" alt="students" src={src} />
            )}
          </ProgressiveImage>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
