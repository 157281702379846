import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from '../components/HomePage';
import AboutPage from '../components/AboutPage';
import ContactPage from '../components/ContactPage';
import AdminLoginPage from '../components/AdminLoginPage';
import ScrollToTop from '../components/ScrollToTop';

const AppRouter = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/about" component={AboutPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/login" component={AdminLoginPage} />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
