import React from 'react';
import missionsData from '../../constants/missions';

const Missions = () => {
  return (
    <div className="missions">
      {
        missionsData.map(({ id, name, desc }) => (
          <div key={id} className="mission-card">
            <div className="mission-card__title">{name}</div>
            <div className="mission-card__text">{desc}</div>
          </div>
        ))
      }
    </div>
  )
}

export default Missions;