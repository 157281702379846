import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import featuresData from '../../constants/features';

const Features = () => {
  const smoothScroll = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth'
    });
  };
  return (
    <div id="features" className="features">
      <button className="arrow-up" onClick={() => { smoothScroll('#ecosystem') }}>
        <FaChevronUp />
      </button>
      <div className="features__title">
        FEATURES
        <div className="features__underline" />
      </div>
      {
        featuresData.map(({ id, title, image, desc }) => (
          <div key={id} className="feature-card">
            <div className="feature-card__title">{title}</div>
            <img className="feature-card__image" alt={title} src={image} />
            <div className="feature-card__text">{desc}</div>
          </div>
        ))
      }
    </div>
  );
};

export default Features;