export default [
  {
    id: 1,
    name: 'Student + Parent App',
    desc: 'Students and parents can enter their demand for bus transportation, send notes to school admins, and live track their buses.'
  },
  {
    id: 2,
    name: 'Bus Driver App',
    desc: 'Admins can securely track their fleet, manage personnel, an maintain speed and inspection data for each of their buses.'
  },
  {
    id: 3,
    name: 'Admin Interface',
    desc: 'Drivers can safely navigate their routes and conduct their routine bus inspections.'
  },
];
