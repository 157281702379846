import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import diagram from '../../assets/ecosystem.png';
import ecosystemData from '../../constants/ecosystem';

const Ecosystem = () => {
  const smoothScroll = (id) => {
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth'
    });
  };
  return (
    <div className="ecosystem" id="ecosystem">
      <button className="arrow-up" onClick={() => { smoothScroll('#hero') }}>
        <FaChevronUp />
      </button>
      <img className="ecosystem__image" alt="ecosystem" src={diagram} />
      <div className="ecosystem__info">
        <div className="ecosystem__title">OUR ECOSYSTEM</div>
        <div className="ecosystem__underline" />
        {ecosystemData.map(({ id, name, desc }) => (
          <div key={id} className="ecosystem__text">
            <div className={`ecosystem__circle-` + id} />
            <div>{desc}</div>
          </div>
        ))}
      </div>
      <button className="arrow-down" onClick={() => { smoothScroll('#features') }}>
        <FaChevronDown />
      </button>
    </div>
  );
};

export default Ecosystem;