import React, { useState } from 'react';
import ProgressiveImage from 'react-progressive-image';
import Header from './Header';
import contactImg from '../assets/contact.jpg';
import compressedContactImg from '../assets/compressed-contact.jpg';

const ContactPage = () => {
  const [message, setMessage] = useState('');
  const onClick = async () => {
    const form = document.getElementById('form');
    const firstInput = document.getElementById('first').value;
    const lastInput = document.getElementById('last').value;
    const emailInput = document.getElementById('email').value;
    const messageInput = document.getElementById('message').value;
    if (firstInput && lastInput && emailInput && messageInput) {
      await form.submit();
      form.reset();
      setMessage('Thanks for sending us a message!');
    } else {
      setMessage('Please fill in all the fields');
    }
  };
  return (
    <div>
      <Header />
      <div className="contact">
        <div className="contact__info">
          <div className="contact__title">Contact Us</div>
          <div className="contact__text">
            Have an inquiry or some feedback for us? Fill out the form below to
            contact our team
          </div>
          <iframe
            title="dummyframe"
            name="dummyframe"
            id="dummyframe"
            style={{ display: 'none' }}
          />
          <form
            id="form"
            className="contact__form"
            autoComplete="off"
            action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdqH0HOKrALEMdBC4nm2li4pBjXrz89lxGAjx12xoEek9RGLg/formResponse"
            target="dummyframe"
          >
            <div className="contact__form-item--small">
              First
              <input
                id="first"
                type="text"
                className="contact__input"
                autoComplete="off"
                name="entry.1730899635"
                required
              />
            </div>
            <div className="contact__form-item--small">
              Last
              <input
                id="last"
                type="text"
                className="contact__input"
                autoComplete="off"
                name="entry.391543578"
                required
              />
            </div>
            <div className="contact__form-item--big">
              Email address
              <input
                id="email"
                type="text"
                className="contact__input"
                autoComplete="off"
                name="entry.33035500"
                required
              />
            </div>
            <div className="contact__form-item--big">
              Message
              <textarea
                id="message"
                className="contact__text-area"
                autoComplete="off"
                name="entry.750908724"
                required
              />
            </div>
          </form>
          <button onClick={onClick} className="contact__button">
            SUBMIT
          </button>
          {message && <div className="contact__text">{message}</div>}
        </div>
        <div className="contact__triangle" />
        <div className="contact__image-container">
          <ProgressiveImage src={contactImg} placeholder={compressedContactImg}>
            {(src) => <img className="contact__image" alt="bus" src={src} />}
          </ProgressiveImage>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
